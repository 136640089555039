<template>
  <div
    class="grid aspect-[1.7]"
    style="background-image: url(/images/dorito-card-bg.png); background-size: 100% 100%"
  >
    <template v-for="(_, index) in Array(2).fill(0)" :key="index">
      <div
        class="larken col-start-1 row-start-1 grid items-center justify-items-center gap-2.5 bg-[#914400] bg-clip-text px-12 py-5 text-center text-transparent mix-blend-darken"
        :class="index === 0 ? '' : 'dorito-shadow'"
        :style="
          index === 0
            ? 'background-image: url(/images/dorito-text-bg.jpg); background-size: 100% 100%'
            : 'background-color: #000a !important'
        "
      >
        <span class="text-xs text-black/20">You're a member! Enjoy your perks</span>
        <div class="grid text-2xl uppercase">
          <span>Country Club</span>
          <span>Membership</span>
        </div>
        <div class="flex items-center gap-2 text-xs">
          <span>You own</span>
          <Icon class="text-[#422a12]" icon="uit:wallet" />
          <span>{{ CCBalance }} $DORITO</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { storeToRefs } from 'pinia'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'

const { CCBalance } = storeToRefs(useSwapkitWalletStore())
</script>

<style>
.dorito-shadow {
  mix-blend-mode: multiply;
}
.dorito-shadow > * {
  text-shadow: 0.01em 0.08em 0.05em white;
}
</style>
