<template>
  <div
    ref="memberHeader"
    class="group/ccc-member-badge relative max-md:hidden"
    role="button"
    tabindex="0"
    @click="onClick"
  >
    <div
      class="larken grid h-full w-[70px] cursor-pointer grid-rows-[1fr_auto] overflow-hidden rounded-2xl bg-[url('/images/dorito.png')] bg-contain bg-center ring-[#ECBA33] hover:ring-1 max-md:w-14"
      :class="isCCMember ? 'bg-[#182019]' : 'bg-[#191919]'"
    >
      <div
        class="col-start-1 row-start-2 whitespace-nowrap p-1 text-center text-[10px] leading-none"
        :class="isCCMember ? 'bg-[#21472e] text-[#3bf992]' : 'bg-[#423819] text-[#ECBA33]'"
      >
        {{ isCCMember ? 'Club Member' : '$DORITO' }}
      </div>
    </div>
    <div
      v-if="isCCMember"
      class="top-100% absolute right-1/2 z-[1] hidden translate-x-1/2 pt-2.5 md:group-hover/ccc-member-badge:flex"
    >
      <GlobalDoritoCard class="w-max" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'
import { useDoritoJoinModalStore } from '~/store/doritoJoinModalStore'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'

const { isCCMember } = storeToRefs(useSwapkitWalletStore())

const doritoJoinModalStore = useDoritoJoinModalStore()
const { isDoritoJoinModalVisible } = storeToRefs(doritoJoinModalStore)
const { showDoritoJoinModal, hideDoritoJoinModal, setFlagCloseOnMouseOut } = doritoJoinModalStore

const memberHeader = ref<HTMLElement>()

const handleCloseDoritoJoinModal = (e: MouseEvent) => {
  if (memberHeader.value) {
    const { top, left, width, height } = memberHeader.value.getBoundingClientRect()
    if (
      ((e.clientX < left || e.clientX > left + width) && e.clientY < top + height) ||
      e.clientY < top
    ) {
      hideDoritoJoinModal()
      setFlagCloseOnMouseOut(false)
    }
  }
}

watch(isDoritoJoinModalVisible, () => {
  if (!isDoritoJoinModalVisible.value) {
    window.removeEventListener('mousemove', handleCloseDoritoJoinModal)
  }
})

const onClick = (e: Event) => {
  e.preventDefault()
  e.stopPropagation()
  if (!isCCMember.value) {
    showDoritoJoinModal()
  }
}
</script>
